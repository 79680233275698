import styles from "./ExperienceContainer.module.css";

function ExperienceContainer({ company, position, date, description, link }) {
  return (
    <div className={styles.experience}>
      <div className={styles.flex}>
        <h3>
          <a href={link}>{company}</a>
        </h3>
        <h3>{date}</h3>
      </div>
      <h2>{position}</h2>
      <h5>{description}</h5>
    </div>
  );
}

export default ExperienceContainer;
