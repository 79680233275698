import styles from "./Home.module.css";
import ExperienceContainer from "../components/ExperienceContainer";
import ProjectContainer from "../components/ProjectContainer";

function Home() {
  const experiences = [
    {
      company: "Canada Super Spelling Bee",
      position:
        "Co-Founder, Software Developer and Competition Committee Executive Lead",
      description:
        "Act as executive lead for Canada’s not-for-profit spelling bee organization. Spearhead global competitions with over 6,000 spellers per year competing across the world. Oversee online weekly courses with over 60 students per season. Reinvest over $200,000 in revenue into students. Designed and programmed media content and speller portal. ",
      date: "July 2018 - Present",
      link: "https://canadaspellingbee.com/",
    },
    {
      company: "Ottawa Catholic School Board",
      position: "Student Trustee",
      description:
        "Provide student voice and insights into school plans at bi-monthly board meetings. Revised board policies for  new 2024 - 2029 school board policy cycle. Attend provincial trustee conferences to collaborate on Indigenous equity and student transportation initiatives. ",
      date: "September 2023 - July 2024",
      link: "https://ocsb.ca/",
    },
    {
      company: "Project Tech Careers",
      position: "Full Stack Developer",
      description:
        "Website developer for Project Tech Careers, a youth technology charity. Design and create components for monthly coding challenges and newsletter sign up pages. Lead meetings, guiding team in help desk tutorials. Develop frontend and backend architecture for hackers across North America. ",
      date: "August 2023 - July 2024",
      link: "https://projecttechcareers.com/",
    },
  ];

  const projects = [
    {
      title: "🦌Reindeer Hunt Licenser🎅",
      description: `Web tool to create school activity PDF licenses from spreadsheet files of student names and homerooms. Auto-randomizes and assigns pairs for student licenses in printable format. Developed with Typescript, JsPDF, and Tailwind.`,
      link: "https://reindeer.jadenzhang.com/",
    },
    {
      title: "🔬Mineral Spectrophotometry Toolbox💡",
      description:
        "Tools to aid science fair research in spectrophotometry using GoDirect Vernier SpectroVis Plus. Performs data normalization, reformatting and visual analysis on .csv files of mineral spectral signatures for Machine Learning use. Developed with various tools such as Python, NumPy, SciKitLearn, MatPlotLib, and Tensorflow.",
      link: "https://github.com/Jantomz/spectrophotometry-tools",
    },
    {
      title: "📚RPGenius (NightHacks Winner)🎮",
      description:
        "24hr hackathon project for NightHacks 2023 to aid with studying using gamified rpg characters and time-blocking. Custom art, music and design. Includes flashcards and point system with timer to allow students to study in an RPG universe! Developed with React and Tailwind. ",
      link: "https://rpgenius.jadenzhang.com/",
    },
  ];

  return (
    <main className={styles.main}>
      <header className={styles.header}>
        <h4>
          Hi, I'm <span className={styles.name}>Jaden Zhang</span>.
        </h4>
        <div className={styles.navCons}>
          <a href="mailto: mail@jadenzhang.com">
            <i className="fa-regular fa-envelope"></i>
          </a>
          <a href="https://www.instagram.com/jadenqzhang/" target="_blank">
            <i className="fa-brands fa-instagram"></i>
          </a>
          <a href="https://www.linkedin.com/in/jadenqzhang/" target="_blank">
            <i className="fa-brands fa-linkedin"></i>
          </a>
          <a href="https://github.com/Jantomz" target="_blank">
            <i className="fa-brands fa-github"></i>
          </a>
        </div>

        <a href="/JadenZhangResume.pdf" target="_blank">
          <p>Resume</p>
        </a>
        <br />

        <br />
        <a
          href="#projects"
          onClick={() =>
            window.scrollTo({ top: window.innerHeight, behavior: "smooth" })
          }
        >
          <p className={styles.scrollText}>Scroll</p>
          <i className="fa-solid fa-chevron-down"></i>
        </a>
      </header>
      <section id="projects" className={styles.information}>
        <h4>Project Gallery</h4>
        <div className={styles.projectContainer}>
          {projects.map((project, index) => (
            <ProjectContainer
              key={index}
              title={project.title}
              description={project.description}
              link={project.link}
            />
          ))}
        </div>
      </section>
      {/* <section id="information" className={styles.information}>
        <h4>Professional Experience</h4>
        <div className={styles.experienceContainer}>
          {experiences.map((experience, index) => (
            <ExperienceContainer
              key={index}
              company={experience.company}
              position={experience.position}
              date={experience.date}
              description={experience.description}
              link={experience.link}
            />
          ))}
        </div>
      </section> */}
    </main>
  );
}

export default Home;
