import styles from "./ProjectContainer.module.css";

// for the future, add pages and github links and everything

export default function ProjectContainer({ title, description, image, link }) {
  return (
    <a className={styles.container} href={link} target="_blank">
      <h1>{title}</h1>
      <h2>{description}</h2>
    </a>
  );
}
